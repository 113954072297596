
import { Permission } from '@/enums/permission'
import { IUser } from '@/interfaces/IUser'
import { usersStore } from '@/store/usersStore'
import { userStore } from '@/store/userStore'
import { shopsStore } from '@/store/shopsStore'
import { BvEvent } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class UserEditModal extends Vue {
  state: 'edit' | 'create' = 'create'
  pending = false

  id: string | null = null
  email = ''
  permissions: Permission[] = []
  selectedShops: string[] = []

  errors: { [key: string]: string | null } = {
    email: null,
  }

  get isAdmin() {
    return userStore.isAdmin
  }

  get allPermissions() {
    const permissions: {
      text: string
      value: Permission
      disabled?: boolean
    }[] = [
      {
        text: 'Администратор',
        value: Permission.admin,
        disabled: !this.isAdmin || userStore.user!.id === this.id,
      },
      {
        text: 'Редактирование меню',
        value: Permission.productsEdit,
      },
      {
        text: 'Редактирование заказов',
        value: Permission.ordersEdit,
      },
      {
        text: 'Редактирование пользователей',
        value: Permission.usersEdit,
      },
      {
        text: 'Редактирование магазинов',
        value: Permission.shopsEdit,
      },
      {
        text: 'Редактирование прайса',
        value: Permission.priceEdit,
      },
    ]

    return permissions
  }

  get shops() {
    return shopsStore.shops
  }

  get modalTitle() {
    return this.state === 'create'
      ? 'Создание пользователя'
      : 'Редактирование пользователя'
  }

  get okTitle() {
    return this.state === 'create' ? 'Создать' : 'Сохранить'
  }

  show(user?: IUser) {
    this.state = user ? 'edit' : 'create'

    if (user) {
      this.email = user.email
      this.id = user.id
      this.permissions = [...user.permissions]
      this.selectedShops = [...user.shops]
    }

    this.$bvModal.show('edit-product-modal')
  }

  async handleOk(e: BvEvent) {
    e.preventDefault()
    if (!this.validate()) {
      return
    }

    this.pending = true

    const payload = this.getPayload()

    if (this.state === 'create') {
      if (!(await usersStore.createUser(payload))) {
        this.pending = false
        return
      }
    } else {
      if (!(await usersStore.updateUser(payload))) {
        this.pending = false
        return
      }
    }

    this.$bvModal.hide('edit-product-modal')
  }

  getPayload() {
    return {
      id: this.id!,
      email: this.email,
      permissions: this.permissions,
      shops: this.selectedShops,
    }
  }

  validate() {
    this.resetErrors()

    const re = /\S+@\S+\.\S+/
    if (!re.test(this.email)) {
      this.errors.email = 'Введите корректный e-mail'
    }

    for (const key in this.errors) {
      const error = this.errors[key]
      if (error) {
        return false
      }
    }

    return true
  }

  resetErrors() {
    for (const key in this.errors) {
      this.errors[key] = null
    }
  }

  reset() {
    this.resetErrors()

    this.permissions = []
    this.email = ''
    this.id = null
    this.selectedShops = []
    this.pending = false
  }
}
