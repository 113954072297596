
import { BButton, BTable, BBadge } from 'bootstrap-vue'
import { Component, Vue, Ref } from 'vue-property-decorator'
import { usersStore } from '@/store/usersStore'
import { Permission } from '@/enums/permission'
import { IUser } from '@/interfaces/IUser'
import { userStore } from '@/store/userStore'
import { showError } from '@/helpers/notifications'
import { shopsStore } from '@/store/shopsStore'
import DeleteConfirmationModal from '@/components/modals/DeleteConfirmation.vue'
import axios from '@/utils/axios'

@Component({
  components: {
    BButton,
    BTable,
    BBadge,
    DeleteConfirmationModal,
  },
})
export default class UsersTable extends Vue {
  @Ref() readonly deleteConfirmationModal!: {
    show: (title: string) => Promise<boolean>
  }

  fields = [
    {
      key: 'email',
      label: 'E-mail',
      sortable: true,
    },
    {
      key: 'permissions',
      label: 'Права',
      sortable: false,
      tdClass: 'permissions',
    },
    {
      key: 'shops',
      label: 'Магазины',
      sortable: false,
    },
    {
      key: 'buttons',
      label: '',
      sortable: false,
    },
  ]

  get users() {
    return usersStore.users
  }

  async mounted() {
    await usersStore.fetchUsers()
  }

  editUserPermissions(user: IUser) {
    if (
      user.permissions.includes(Permission.admin) &&
      !userStore.user?.permissions.includes(Permission.admin)
    ) {
      return showError('Вы не можете редактировать администратора')
    }
    this.$emit('editUser', user)
  }

  getPermissionTitle(permission: Permission) {
    switch (permission) {
      case Permission.admin:
        return 'Администратор'
      case Permission.productsEdit:
        return 'Редактирование меню'
      case Permission.ordersEdit:
        return 'Редактирование заказов'
      case Permission.usersEdit:
        return 'Редактирование пользователей'
      case Permission.shopsEdit:
        return 'Редактирование магазинов'
      case Permission.priceEdit:
        return 'Редактирование прайса'
      default:
        return permission
    }
  }

  getVariant(permission: Permission) {
    switch (permission) {
      case Permission.admin:
        return 'danger'
      case Permission.productsEdit:
        return 'info'
      case Permission.priceEdit:
        return 'info'
      case Permission.ordersEdit:
        return 'primary'
      case Permission.usersEdit:
        return 'warning'
      case Permission.shopsEdit:
        return 'success'
    }
  }

  getShopTitle(shopid: string) {
    return shopsStore.shops.find(shop => shop.id === shopid)?.title
  }

  async deleteUser(user: IUser) {
    const result = await this.deleteConfirmationModal.show(user.email)
    if (result) {
      usersStore.deleteUser(user.id)
    }
  }

  async resetPassword(user: IUser) {
    const result = await this.$bvModal.msgBoxConfirm(
      `Вы действитеьно хотите сбросить пароль пользователю ${user.email}?`,
      {
        title: 'Сброс пароля',
        size: 'md',
        okTitle: 'Сбросить',
        cancelTitle: 'Отмена',
        centered: true,
      },
    )

    if (!result) {
      return
    }

    try {
      await axios.post(`users/reset-password/${user.id}`)
    } catch (error) {
      console.error(error)
      showError('При сбросе пароля произошла ошибка')
    }
  }
}
