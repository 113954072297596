import Vue from 'vue'
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import axios, { isAxiosError } from '@/utils/axios'
import { IUser } from '@/interfaces/IUser'
import { showError, showSuccess } from '@/helpers/notifications'
import { AxiosError, AxiosResponse } from 'axios'
import { Permission } from '@/enums/permission'

@Module({
  name: 'usersStore',
  namespaced: true,
  dynamic: true,
  store,
})
export default class UsersStore extends VuexModule {
  private _users: IUser[] = []

  get users() {
    return this._users
  }

  @Mutation
  private setUsers(users: IUser[]) {
    this._users = users
  }

  @Mutation
  private updateUserLocal(user: IUser) {
    const index = this._users.findIndex(u => u.id === user.id)
    Vue.set(this._users, index, user)
  }

  @Mutation
  private addUserLocal(user: IUser) {
    this._users.push(user)
  }

  @Mutation
  private deleteUserLocal(id: string) {
    this._users = this._users.filter(u => u.id !== id)
  }

  @Action
  async fetchUsers() {
    try {
      const response: AxiosResponse<IUser[]> = await axios.get('users')
      this.setUsers(response.data)
    } catch (error) {
      console.error(error)
      showError('Произошла ошибка при получении пользователей')
    }
  }

  @Action
  async updateUser(payload: {
    id: string
    email: string
    permissions: Permission[]
    shops: string[]
  }) {
    try {
      const response: AxiosResponse<IUser> = await axios.patch(`users/${payload.id}`, payload)
      this.updateUserLocal(response.data)
      showSuccess('Пользователь обновлен')
      return true
    } catch (error) {
      if (isAxiosError(error) && (error as AxiosError).response?.status === 400) {
        showError((error as AxiosError).response?.data)
      } else {
        console.error(error)
        showError('Произошла ошибка при редактировании пользователя')
      }
      return false
    }
  }

  @Action
  async createUser(payload: {
    id: string
    email: string
    permissions: Permission[]
    shops: string[]
  }) {
    try {
      const response: AxiosResponse<IUser> = await axios.post(`users`, payload)
      this.addUserLocal(response.data)
      showSuccess('Пользователь добавлен')
      return true
    } catch (error) {
      if (isAxiosError(error) && (error as AxiosError).response?.status === 400) {
        showError((error as AxiosError).response?.data)
      } else {
        console.error(error)
        showError('Произошла ошибка при добавлении пользователя')
      }
      return false
    }
  }

  @Action
  async deleteUser(id: string) {
    try {
      await axios.delete(`users/${id}`)
      this.deleteUserLocal(id)
      showSuccess('Пользователь удалён')
    } catch (error) {
      if (isAxiosError(error) && (error as AxiosError).response?.status === 400) {
        showError((error as AxiosError).response?.data)
      } else {
        console.error(error)
        showError('Произошла ошибка при удалении пользователя')
      }
    }
  }
}

export const usersStore = getModule(UsersStore)
