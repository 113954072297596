
import UsersTable from '@/components/users/UsersTable.vue'
import { IUser } from '@/interfaces/IUser'
import { Component, Vue, Ref } from 'vue-property-decorator'
import UserEditModal from '@/components/modals/UserEdit.vue'

@Component({
  components: { UserEditModal, UsersTable },
})
export default class Users extends Vue {
  @Ref() readonly userEditModal!: { show: (user?: IUser) => void }

  openUserModal(user?: IUser) {
    if (user) {
      this.userEditModal.show(user)
    } else {
      this.userEditModal.show()
    }
  }
}
